import { agregarReporte } from '../api/apiReportes'

import { useState } from 'react'
import Arrow from '../assetss/arrow-down.svg'
import '../styles/components/switch.css'
const Switch = ({ COLOR, pos, id_switch, setReportesAux, reportesAux, setReportes, reportes, colorActual }) => {
  const STATE_REPORT = {
    red: 'color-1',
    yellow: 'color-2',
    green: 'color-3'
  }

  const [menuState, setMenuState] = useState(false)
  const [stateActual, setStateActual] = useState(undefined)

  const changeStateSwitch = async (state, pos, id_switch) => {
    // console.log(state)
    const data = new FormData()
    data.append('estado_pago', state)
    data.append('id_sisvadi', id_switch)
    data.append('accion', 'editar_pago')
    const response = await agregarReporte(data)
    if (response === 'Registrado correctamente') {
      let color;
      switch (colorActual) {
        case 'color-1':
          color = 'red'
          break;
        case 'color-2':
          color = 'yellow'
          break;
        case 'color-3':
          color = 'green'
          break;
        default:
          color = 'white'
          break;
      }

      reportesAux.map(reporte => {
        if (reporte.id_sisvadi === id_switch) {
          reporte.estado_pago = state;
        }
      })

      reportes.map(reporte => {
        if (reporte.id_sisvadi === id_switch) {
          reporte.estado_pago = state;
        }
      })
      
      if (color === "white") {
        reportesAux = reportesAux.filter(reporte => reporte.estado_pago === "red" || reporte.estado_pago === "yellow" || reporte.estado_pago === "green")
        reportes = reportes.filter(reporte => reporte.estado_pago === "red" || reporte.estado_pago === "yellow" || reporte.estado_pago === "green")
      } else {
        reportes = reportes.filter(reporte => reporte.estado_pago === color)
        reportesAux = reportesAux.filter(reporte => reporte.estado_pago === color)
      }

     
      // changeState(colorActual)
      setReportesAux(reportesAux)
      setReportes(reportes)
      setStateActual(STATE_REPORT[state])
      showSwitch(pos)
    }
  }
  const showSwitch = pos => {
    if (
      document.querySelectorAll('.switch-arrow')[pos].classList.contains('spin')
    ) {
      document.querySelectorAll('.switch-arrow')[pos].classList.remove('spin')
    } else {
      document.querySelectorAll('.switch-arrow')[pos].classList.add('spin')
    }
    setMenuState(!menuState)
  }
  return (
    <div className='switch'>
      <div className={`color color_act ${STATE_REPORT[COLOR]}`}>

      </div>
      <img
        className='switch-arrow'
        onClick={() => showSwitch(pos + 1)}
        src={Arrow}
        alt=''
      />
      {menuState && (
        <div className='switch-menu'>
          <div
            className='color color-1'
            onClick={() => changeStateSwitch('red', pos + 1, id_switch)}
          ></div>
          <div
            className='color color-2'
            onClick={() => changeStateSwitch('yellow', pos + 1, id_switch)}
          ></div>
          <div
            className='color color-3'
            onClick={() => changeStateSwitch('green', pos + 1, id_switch)}
          ></div>
        </div>
      )}
    </div>
  )
}
export default Switch
