import { agregarReporte, traerReportes } from "../../api/apiReportes";
import { useState } from "react";

import Papelera from "../../assetss/papelera.svg";

export const FormBorrar = ({
  setContentAlert,
  setReportes,
  reporte,
  modalReporte,
  pos,
}) => {
  const [idSisvadiDelete, setIdSisvadiDelete] = useState(reporte.id_sisvadi);
  const [borradorLogico, setBorradorLogico] = useState(reporte.borrador_logico);

  const [btnBorrar, setBtnBorrar] = useState("Borrar");
  const [disabled, setDisabled] = useState(true);

  const eliminarReporte = async (e, pos) => {
    e.preventDefault();
    setBtnBorrar("borrando...");
    setDisabled(false);
    const data = new FormData(e.target);
    data.append("accion", "borrar_reporte");
    const response = await agregarReporte(data);
    if (response === "Registrado correctamente") {
      let arr = await traerReportes();
      setBtnBorrar("Borrar");
      setDisabled(true);
      setTimeout(() => {
        modalReporte(".modalBorrar", pos);
        setContentAlert("Eliminar");
        setTimeout(() => {
          setContentAlert(undefined);
        }, 4000);
        // alertMessage('Agregado correctamente!')
      }, 1000);
      setTimeout(async () => {
        setReportes(arr.data.filter((rep) => rep.borrador_logico !== "0"));
      }, 1500);
    }
  };

  return (
    <div
      className="modal fade modalBorrar"
      id=""
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-delete">
        <div className="modal-content">
          <div className="modal-header">
            <img src={Papelera} alt="" />
            <h3 className="modal-title" id="exampleModalLabel">
              Borrar Definitivamente
            </h3>
          </div>
          <div className="modal-body">
            ¿Esta seguro que desea borrar definitivamente el trabajo{" "}
            <b>N° {idSisvadiDelete}</b>?
            <form
              onSubmit={(e) => eliminarReporte(e, pos)}
              className="eliminar_reporte"
            >
              <input
                style={{ display: "none" }}
                type="text"
                name="id_sisvadi"
                onChange={(e) => setIdSisvadiDelete(e.target.value)}
                value={idSisvadiDelete}
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="borrador_logico"
                onChange={(e) => setBorradorLogico(e.target.value)}
                value={borradorLogico}
              />
              <div className="btn-actions delete">
                <button
                  type="button"
                  className="btn-close btnFormAgregar"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => modalReporte(".modalBorrar", pos)}
                >
                  Cancelar
                </button>
                {disabled && (
                  <button type="submit" className="btn btn-danger">
                    <img src={Papelera} alt="" />
                    {btnBorrar}
                  </button>
                )}

                {!disabled && (
                  <button type="submit" disabled className="btn btn-danger">
                    <img src={Papelera} alt="" />
                    {btnBorrar}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
