
const url = 'https://cotytel.com/api-php/'

export const login = async (data) => {
    let endpoint = url + 'usuario/index.php'
    const response = await fetch(endpoint, {
        method: "POST",
        body: data,
        headers: {
            Accept: 'application/json'
        }
    })

    return response.json()
}