import { useState } from 'react'
import { Header } from '../components/Header'
import { FormBorrar } from '../components/actionsForm/FormBorrar'
import { traerReportes } from '../api/apiReportes'
import Alert from '../components/alerts/Alert'
import ReportBoard from '../components/ReportBoard'
import { Animated } from 'react-animated-css'

import { useNavigate, Navigate } from 'react-router-dom'

export const ListadoBorrados = () => {
  const navigate = useNavigate()


  const [contentAlert, setContentAlert] = useState()
  const [reportes, setReportes] = useState([])
  const [reportesAux, setReportesAux] = useState([])
  const [stateReportes, setStateReportes] = useState(false)

  if (!localStorage.getItem('usuario')) {
    return <Navigate to={'/'} />;
  }

  const getReportes = async () => {
    const response = await traerReportes()
    if (response.status === 200) {
      setReportes(response.data.filter(rep => rep.borrador_logico !== '0'))
      setReportesAux(response.data.filter(rep => rep.borrador_logico !== '0'))
      setStateReportes(true)
    }
  }
  if (!stateReportes) {
    getReportes()
  }

  const modalReport = (div, pos) => {
    if (document.querySelectorAll(div)[pos].classList.contains('show')) {
      document.querySelectorAll(div)[pos].classList.remove('show')
    } else {
      document.querySelectorAll(div)[pos].classList.add('show')
    }
  }

  return (
    <div className='home'>
      {contentAlert && <Alert contentAlert={contentAlert} />}
      <Header
        setReportes={setReportes}
        modalReport={modalReport}
        screen={'papelera'}
        reportesAux={reportesAux}
      />
      <Animated animationIn='fadeInUp' animationOut='fadeOut' isVisible={true}>
        <ReportBoard
          setContentAlert={setContentAlert}
          setReportesAux={setReportesAux}
          reportesAux={reportesAux}
          setReportes={setReportes}
          reportes={reportes}
          screen={'papelera'}
          modalReport={modalReport}
        />
      </Animated>
    </div>
  )
}
export default ListadoBorrados
