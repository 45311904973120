import { useState } from 'react'
import { traerReportes } from '../api/apiReportes'
import { Header } from '../components/Header'
import ReportBoard from '../components/ReportBoard'
import Alert from '../components/alerts/Alert'
import { Animated } from 'react-animated-css'

import { useNavigate, Navigate } from 'react-router-dom'

import '../styles/home.css'
const Home = () => {
  const navigate = useNavigate()
  
  const [contentAlert, setContentAlert] = useState()
  const [reportes, setReportes] = useState(undefined)

  const [reportesAux, setReportesAux] = useState(undefined)
  const [stateReportes, setStateReportes] = useState(false)

  if (!localStorage.getItem('usuario')) {
    return <Navigate to={'/'} />;
  }
  const getReportes = async () => {
    const response = await traerReportes()
    if (response.status === 200) {
      setTimeout(() => {
        setReportes(response.data.filter(rep => rep.borrador_logico !== '1'))
        setReportesAux(response.data.filter(rep => rep.borrador_logico !== '1'))
        setStateReportes(true)
      }, 1000)
    }
  }
  if (!stateReportes) {
    getReportes()
  }

  const modalReport = (div, pos) => {
    if (document.querySelectorAll(div)[pos].classList.contains('show')) {
      document.querySelectorAll(div)[pos].classList.remove('show')
    } else {
      document.querySelectorAll(div)[pos].classList.add('show')
    }
  }

  return (
    <div className='home'>
      {contentAlert && <Alert type={"Reportes"} contentAlert={contentAlert} />}
      <Header
        setReportes={setReportes}
        modalReport={modalReport}
        screen={'principal'}
        reportesAux={reportesAux}
        setContentAlert={setContentAlert}
      />
      <Animated animationIn='fadeInUp' animationOut='fadeOut' isVisible={true}>
        <ReportBoard
          setContentAlert={setContentAlert}
          setReportesAux={setReportesAux}
          reportesAux={reportesAux}
          setReportes={setReportes}
          reportes={reportes}
          screen={'principal'}
          modalReport={modalReport}
        />
      </Animated>
    </div>
  )
}
export default Home
