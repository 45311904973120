import { Link } from 'react-router-dom'
import Button from './Button'
import { FormAgregar } from './actionsForm/FormAgregar'

import Lupa from '../assetss/browse.svg'
import Papelera from '../assetss/papelera.svg'
import Principal from '../assetss/grid.svg'
import Logo from '../assetss/logo.svg'

import '../styles/header.css'

export const Header = ({ setReportes, modalReport, screen, reportesAux, setContentAlert }) => {
  const searchReporte = e => {
    const results =
      e.target.value.length === 0
        ? reportesAux
        : reportesAux.filter(
          report => String(report.id_sisvadi).indexOf(e.target.value) !== -1
        )
    setReportes(results)
  }
  return (
    <header>
      <Link to={'/home'} className='logo'>
        {/* <h1>Cotytel</h1> */}
        <img src={Logo} alt="" />
      </Link>
      <div className='menu'>
        <Link
          to='/home'
          className={`item-menu ${screen === 'principal' ? 'active' : ''}`}
        >
          <img src={Principal} alt='' />
          <h3>Principal</h3>
        </Link>
        <Link
          to='/borrados'
          className={`item-menu ${screen === 'papelera' ? 'active' : ''}`}
        >
          <img src={Papelera} alt='' />
          <h3>Papelera</h3>
        </Link>
      </div>

      <div className='panel-options'>
        <div className='searchbar'>
          <img className='icon' src={Lupa} alt="" />
          <input
            type='number'
            name='searchById'
            placeholder='Buscar SISVADI...'
            onChange={e => searchReporte(e)}
          />
        </div>
        {screen === 'principal' && (
          <>
            <Button
              contentButton={'Agregar'}
              buttonAction={modalReport}
              firstParam={'.modalAgregar'}
              secondParam={0}
            />
            <FormAgregar setContentAlert={setContentAlert} modalReporte={modalReport} setReportes={setReportes} />
          </>
        )}
      </div>
    </header>
  )
}
