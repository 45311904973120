import Button from './Button'
import Switch from './Switch'
import { FormBorrar } from './actionsForm/FormBorrar'
import { FormEliminar } from './actionsForm/FormEliminar'
import { FormEditar } from './actionsForm/FormEditar'
import Arrow from '../assetss/arrow-down.svg'

import { saveAs } from 'file-saver'
import "../styles/components/switch.css"
import { FormRestaurar } from './actionsForm/FormRestaurar'
const ReportContainer = ({
  setReportesAux,
  reportesAux,
  setReportes,
  reportes,
  reporte,
  pos,
  screen,
  modalReport,
  setContentAlert,
  colorActual
}) => {
  const openReport = id => {
    const reports = document.querySelectorAll('.report')
    if (reports[id].classList.contains('open')) {
      reports[id].classList.remove('open')
    } else {
      reports[id].classList.add('open')
    }
  }
  const downloadReport = file => {

    saveAs(`https://cotytel.com/api-php/archivos_reportes/${file}`, `${file}`)

  }



  return (
    <div className='report'>
      <div className='head-report'>
        <p>
          <Switch
            COLOR={reporte.estado_pago}
            pos={pos}
            id_switch={reporte.id_sisvadi}
            setReportesAux={setReportesAux}
            reportesAux={reportesAux}
            setReportes={setReportes}
            reportes={reportes}
            colorActual={colorActual}
          />
        </p>
        <p>{reporte.fecha_trabajo}</p>
        <p>{reporte.id_sisvadi}</p>
        <p>{reporte.nombre_central}</p>
        <p>{reporte.nombre_empresa}</p>
        <p>{reporte.total}</p>
        <p>{reporte.per_90}</p>
        <p>{reporte.per_10}</p>

        <p>
          <button onClick={() => openReport(pos)}>
            <img src={Arrow} alt='' />
          </button>
        </p>
      </div>
      <div className='body-report'>
        <div className='itinerarios'>
          <div className='itinerario'>
            <span className='title-itiner'>L/2001</span>
            <p className='value-itiner'>{reporte.PL_2001}</p>
          </div>
          <div className='itinerario'>
            <span className='title-itiner'>$L/2001</span>
            <p className='value-itiner'>{reporte.VL_2001}</p>
          </div>
        </div>
        <div className='itinerarios'>
          <div className='itinerario'>
            <span className='title-itiner'>N/2003</span>
            <p className='value-itiner'>{reporte.PN_2003}</p>
          </div>
          <div className='itinerario'>
            <span className='title-itiner'>$N/2003</span>
            <p className='value-itiner'>{reporte.VN_2003}</p>
          </div>
        </div>
        <div className='itinerarios'>
          <div className='itinerario'>
            <span className='title-itiner'>LC/2005</span>
            <p className='value-itiner'>{reporte.PLC_2005}</p>
          </div>
          <div className='itinerario'>
            <span className='title-itiner'>$LC/2005</span>
            <p className='value-itiner'>{reporte.VLC_2005}</p>
          </div>
        </div>
        <div className='itinerarios'>
          <div className='itinerario'>
            <span className='title-itiner'>L2/2006</span>
            <p className='value-itiner'>{reporte.PLZ_2006}</p>
          </div>
          <div className='itinerario'>
            <span className='title-itiner'>$L2/2006</span>
            <p className='value-itiner'>{reporte.VLZ_2006}</p>
          </div>
        </div>
        <div className='observation'>
          <span>Observaciones</span>
          <p>{reporte.observaciones}</p>
        </div>

        <div className="download">
          <Button
            contentButton={'Descargar'}
            buttonAction={downloadReport}
            firstParam={reporte.descarga_materiales}
          />
          <span>{reporte.cantidad_archivos} archivos</span>
        </div>

        {screen === 'principal' && (
          <>
            <div className='options'>
              <Button
                contentButton={'Eliminar'}
                buttonAction={modalReport}
                firstParam={'.modalEliminar'}
                secondParam={pos}
              />
              <FormEliminar
                setContentAlert={setContentAlert}
                setReportes={setReportes}
                modalReporte={modalReport}
                reporte={reporte}
                pos={pos}
              />
              <Button
                contentButton={'Editar'}
                buttonAction={modalReport}
                firstParam={'.modalEditar'}
                secondParam={pos}
              />
              <FormEditar
                setContentAlert={setContentAlert}
                setReportes={setReportes}
                modalReporte={modalReport}
                reporte={reporte}
                pos={pos}
              />
            </div>
          </>
        )}
        {screen === 'papelera' && (
          <>
            <div className='options'>
              <Button
                contentButton={'Restaurar'}
                buttonAction={modalReport}
                firstParam={'.modalRestaurar'}
                secondParam={pos}
              />
              <FormRestaurar
                setContentAlert={setContentAlert}
                setReportes={setReportes}
                modalReporte={modalReport}
                reporte={reporte}
                pos={pos}
              />
              <Button
                contentButton={'Eliminar'}
                buttonAction={modalReport}
                firstParam={'.modalBorrar'}
                secondParam={pos}
              />
              <FormBorrar
                setContentAlert={setContentAlert}
                setReportes={setReportes}
                modalReporte={modalReport}
                reporte={reporte}
                pos={pos}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export default ReportContainer
