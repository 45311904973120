import './App.css'
import { useState } from 'react'
import RouterPage from './router/RouterPage'
import { Header } from './components/Header'
import { traerReportes } from './api/apiReportes'
function App () {
  
  return (
    <div className='App'>
      <RouterPage />
    </div>
  )
}

export default App
