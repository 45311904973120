import ReportContainer from './ReporteContainer'
import Grid from '../assetss/grid.svg'
import Loader from './Loader'
import NotResults from './NotResults'

import Arrow from '../assetss/arrow-down.svg'
import Switch from './Switch'
import { useState } from 'react'

const ReportBoard = ({ setContentAlert, setReportesAux, reportesAux, setReportes, reportes, screen, modalReport }) => {
  const STATE_REPORT = {
    white: 'color-0',
    red: 'color-1',
    yellow: 'color-2',
    green: 'color-3'
  }
  const [menuState, setMenuState] = useState(false)
  const [colorActual, setColorActual] = useState(STATE_REPORT['white'])
  const changeState = (color) => {
    setColorActual(STATE_REPORT[color])
    console.log(color)
    if (color === 'white') {
      setReportes(reportesAux.filter((reporte) => reporte.estado_pago !== 'green'))
    } else {
      
      setReportes(reportesAux.filter((reporte) => reporte.estado_pago === color))
    }
  }
  return (
    <div className='container'>
      <div className='container-reports'>
        <div className='report-null'></div>
        <div className='header-container'>
          <div className='header-reports'>
            <h3>
              <div className="switch">
                <div className={`color color_act ${colorActual}`}></div>
                <img className='switch-arrow' onClick={() => setMenuState(!menuState)} src={Arrow} alt="" />
                {menuState &&
                  <div className='switch-menu'>
                    <div onClick={() => changeState('white')} className="color color-0"></div>
                    <div onClick={() => changeState('red')} className="color color-1"></div>
                    <div onClick={() => changeState('yellow')} className="color color-2"></div>
                    <div onClick={() => changeState('green')} className="color color-3"></div>
                  </div>
                }
              </div>
            </h3>
            <h3>Mes</h3>
            <h3>ID "SISVADI"</h3>
            <h3>Central</h3>
            <h3>Empresa</h3>
            <h3>Total</h3>
            <h3>90%</h3>
            <h3>10%</h3>
            <h3>
              <img src={Grid} alt='' />
            </h3>
          </div>
        </div>
        <div className='list-reports'>
          {reportes === undefined && <Loader />}
          {reportes !== undefined && reportes.length === 0 && <NotResults />}
          {reportes !== undefined &&
            reportes?.map((reporte, index) => (
              <ReportContainer
                key={index}
                setReportesAux={setReportesAux}
                reportesAux={reportesAux}
                setReportes={setReportes}
                reportes={reportes}
                reporte={reporte}
                pos={index}
                screen={screen}
                modalReport={modalReport}
                setContentAlert={setContentAlert}
                colorActual={colorActual}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default ReportBoard
