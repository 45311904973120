import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Index from '../screens/Index'
import Home from '../screens/Home'
import ListadoBorrados from '../screens/ListadoBorrados'

export default function RouterPage() {
  return (

    <Router>
      <Routes>
        <Route path='/' element={<Index />} />
        <Route path='/home' element={<Home />} />
        <Route path='/borrados' element={<ListadoBorrados />} />
      </Routes>
    </Router>

  )
}
