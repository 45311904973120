import { Formik } from 'formik'
import { login } from '../api/apiLogin'
import { Navigate, useNavigate } from 'react-router-dom'

import "../styles/index.css"
import HeaderLogin from '../components/HeaderLogin'
import { useState } from 'react'
import Loader from '../components/Loader'
import Alert from '../components/alerts/Alert'
const Index = () => {
  const navigate = useNavigate()

  const [stateBtn, setStateBtn] = useState("Ingresar")
  const [type, setType] = useState("Login")
  const [contentAlert, setContentAlert] = useState()
  if (localStorage.getItem('usuario')) {
    return <Navigate to={'/home'} />;
  }

  const makeFocus = (event, id) => {
    console.log(event.target.value)
    if (event.target.value.length != "") {
      document.querySelectorAll(".label_form")[id].classList.add("focus")
    } else {
      document.querySelectorAll(".label_form")[id].classList.remove("focus")
    }
  }
  return (
    <div className='index'>
      {contentAlert && <Alert type={type} contentAlert={contentAlert} />}
      <HeaderLogin />
      <Formik
        initialValues={{ email_usuario: '', pwd_usuario: '' }}
        validate={values => {
          const errors = {}
          if (!values.email_usuario) {
            errors.email_usuario = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              values.email_usuario
            )
          ) {
            errors.email_usuario = 'Invalid email address'
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setStateBtn(<Loader />)
          setTimeout(async () => {
            const response = await login(JSON.stringify(values))
            setStateBtn("Ingresar")
            setContentAlert(response)
            if (response === 'Coincide') {
              localStorage.setItem('usuario', JSON.stringify(values))

              window.location.reload()
            }
            setTimeout(() => {
              setContentAlert()
            }, 2500);
            setSubmitting(false)
          }, 400)
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <form className='form' onSubmit={handleSubmit}>
            <h3 className='title_form'>Ingresar</h3>
            <div className='inputs'>
              <label htmlFor='email_usuario' className='label_form'>Email</label>
              <input
                className='input_form'
                type='email'
                name='email_usuario'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email_usuario}
                onKeyUpCapture={(e) => makeFocus(e, 0)}

              />
            </div>
            {/* {errors.email_usuario && touched.email_usuario && errors.email_usuario} */}
            <div className='inputs'>
              <label htmlFor='pwd_usuario' className='label_form'>Contraseña</label>

              <input
                className='input_form'
                type='password'
                name='pwd_usuario'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pwd_usuario}
                onKeyUpCapture={(e) => makeFocus(e, 1)}

              />
            </div>
            {/* {errors.pwd_usuario && touched.pwd_usuario && errors.pwd_usuario} */}
            <button className='btnIngresar' type='submit' disabled={isSubmitting}>
              {stateBtn}
            </button>
          </form>
        )}
      </Formik>
    </div >
  )
}

export default Index
