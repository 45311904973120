import { agregarReporte, traerReportes } from '../../api/apiReportes'
import { NumericFormat } from 'react-number-format'
import { useState } from 'react'

import Agregar from '../../assetss/agregar.svg'
import Descargar from '../../assetss/descargar.svg'
import '../../styles/components/modal.css'

export const FormAgregar = ({ setContentAlert, setReportes, modalReporte }) => {
  const [total, setTotal] = useState(0)
  const [per90, setPer90] = useState(0)
  const [per10, setPer10] = useState(0)

  const [btnAgregar, setBtnAgregar] = useState("Agregar")
  const [disabled, setDisabled] = useState(true)

  const [puntajeValores, setPuntajeValores] = useState([
    { puntaje_L2001: 0, valor_L2001: 0, total: 0 },
    { puntaje_N2003: 0, valor_N2003: 0, total: 0 },
    { puntaje_LC2005: 0, valor_LC2005: 0, total: 0 },
    { puntaje_LZ2006: 0, valor_LZ2006: 0, total: 0 }
  ])

  const changePuntajeValores = (e, pos) => {
    const newArr = [...puntajeValores]
    const objActual = Object.keys(newArr[pos])

    if (e.target.value.length !== 0) {
      newArr[pos][e.target.name] = parseFloat(e.target.value).toFixed(4)
    } else {
      newArr[pos][e.target.name] = 0
    }

    newArr[pos]['total'] = (newArr[pos][objActual[0]] * newArr[pos][objActual[1]])
    const total = newArr.reduce((acum, b) => acum + b['total'], 0).toFixed(4)

    setTotal(total * 1.0)
    setPer90((total * 0.9).toFixed(4) * 1.0)
    setPer10((total * 0.1).toFixed(4) * 1.0)
    setPuntajeValores(newArr)
  }

  const handleSubmit = async e => {
    setBtnAgregar("agregando...")
    setDisabled(false)
    e.preventDefault()
    const data = new FormData(e.target)
    data.append('accion', 'agregar_reporte')
    const response = await agregarReporte(data)

    if (response === 'Registrado correctamente') {
      let arr = await traerReportes()
      setBtnAgregar("Agregar")
      setDisabled(true)
      setTimeout(() => {
        modalReporte('.modalAgregar', 0)
        setContentAlert("Agregar")
        setTimeout(() => {
          setContentAlert(undefined)
        }, 4000);
        // alertMessage('Agregado correctamente!')
      }, 1000)
      setTimeout(async () => {
        setReportes(arr.data.filter(rep => rep.borrador_logico !== '1'))
      }, 1500)
    }
  }
  return (
    <div
      className='modal fade modalAgregar'
      id='exampleModal'
      tabIndex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <img src={Agregar} alt='' />
            <h3 className='modal-title' id='exampleModalLabel'>
              Agregar Reporte
            </h3>
          </div>
          <div className='modal-body'>
            <form
              onSubmit={e => handleSubmit(e)}
              className='agregar_reporte'
              encType='multipart/form-data'
            >
              <div className='head-form'>
                <div className='input'>
                  <input
                    type='month'
                    name='fecha_trabajo'
                    id='fecha_trabajo'
                    required
                    placeholder='Mes/Año'
                  />
                </div>
                <div className='input'>
                  <input
                    type='number'
                    name='id_sisvadi'
                    id='id_sisvadi'
                    placeholder='ID "SISVADI"'
                    required
                  />
                </div>
                <div className='input'>
                  <input
                    type='text'
                    name='nombre_central'
                    id='nombre_central'
                    placeholder='Central'
                    required
                  />
                </div>
                <div className='input'>
                  <input
                    type='text'
                    name='nombre_empresa'
                    id='nombre_empresa'
                    placeholder='Empresa'
                    required
                  />
                </div>
              </div>

              <div className='puntajes-valores'>
                <div className='puntaje-valor'>
                  <div className='input'>
                    <input
                      type='number'
                      name='puntaje_L2001'
                      className='puntaje puntaje_L2001'
                      id='puntaje_L2001'
                      placeholder='L/2001'
                      step=".01"
                      onChange={e => changePuntajeValores(e, 0)}
                    />
                  </div>
                  <div className='input'>
                    <input
                      type='number'
                      name='valor_L2001'
                      className='valor valor_L2001'
                      id='valor_L2001'
                      placeholder='$L/2001'
                      step=".01"
                      onChange={e => changePuntajeValores(e, 0)}
                    />
                  </div>
                </div>
                <div className='puntaje-valor'>
                  <div className='input'>
                    <input
                      type='number'
                      name='puntaje_N2003'
                      className='puntaje puntaje_N2003'
                      id='puntaje_N2003'
                      placeholder='N/2003'
                      step=".01"
                      onChange={e => changePuntajeValores(e, 1)}
                    />
                  </div>
                  <div className='input'>
                    <input
                      type='number'
                      name='valor_N2003'
                      className='valor valor_N2003'
                      id='valor_N2003'
                      placeholder='$N/2003'
                      step=".01"
                      onChange={e => changePuntajeValores(e, 1)}
                    />
                  </div>
                </div>
                <div className='puntaje-valor'>
                  <div className='input'>
                    <input
                      type='number'
                      name='puntaje_LC2005'
                      className='puntaje puntaje_LC2005'
                      id='puntaje_LC2005'
                      placeholder='LC/2005'
                      step=".01"
                      onChange={e => changePuntajeValores(e, 2)}
                    />
                  </div>
                  <div className='input'>
                    <input
                      type='number'
                      name='valor_LC2005'
                      className='valor valor_LC2005'
                      id='valor_LC2005'
                      placeholder='$LC/2005'
                      step=".01"
                      onChange={e => changePuntajeValores(e, 2)}
                    />
                  </div>
                </div>
                <div className='puntaje-valor'>
                  <div className='input'>
                    <input
                      type='number'
                      name='puntaje_LZ2006'
                      className='puntaje puntaje_LZ2006'
                      id='puntaje_LZ2006'
                      placeholder='LZ/2006'
                      step=".01"
                      onChange={e => changePuntajeValores(e, 3)}
                    />
                  </div>
                  <div className='input'>
                    <input
                      type='number'
                      name='valor_LZ2006'
                      className='valor valor_LZ2006'
                      id='valor_LZ2006'
                      placeholder='$LZ/2006'
                      step=".01"
                      onChange={e => changePuntajeValores(e, 3)}
                    />
                  </div>
                </div>
              </div>
              <div className='porcentajes-total'>
                <div className='input'>
                  <NumericFormat
                    thousandSeparator='.'
                    decimalSeparator=','
                    step='.00'
                    name='per_10'
                    className='per_10'
                    id='per_10'
                    value={per10}
                    placeholder='10%'
                    readOnly
                    required
                  />
                </div>
                <div className='input'>
                  <NumericFormat
                    thousandSeparator='.'
                    decimalSeparator=','
                    step='.00'
                    name='per_90'
                    className='per_90'
                    id='per_90'
                    value={per90}
                    placeholder='90%'
                    readOnly
                    required
                  />
                </div>
                <div className='input'>
                  <NumericFormat
                    thousandSeparator='.'
                    decimalSeparator=','
                    step='.0000'
                    name='total'
                    className='total'
                    placeholder='Total'
                    id='total'
                    value={total}
                    readOnly
                    required
                  />
                </div>
              </div>

              <div className='input'>
                <textarea
                  name='observaciones'
                  id='observaciones'
                  cols='30'
                  rows='3'
                  placeholder='Observaciones...'
                ></textarea>
              </div>
              <div className='subir-archivo'>
                <input
                  type='file'
                  name='descarga_materiales[]'
                  id='descarga_materiales'
                  multiple
                />
                {/* <button type='button'>
                  <img src={Descargar} alt='' /> Subir Archivo
                </button> */}
              </div>

              <div className='btn-actions'>
                <button
                  type='button'
                  className='btn-close btnFormAgregar'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => modalReporte('.modalAgregar', 0)}
                >
                  Cancelar
                </button>
                {disabled && 
                <button type='submit'>
                  <img src={Agregar} alt='' />
                  {btnAgregar}
                </button>
                }
                {!disabled &&
                <button type='submit' disabled>
                <img src={Agregar} alt='' />
                {btnAgregar}
              </button>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
