import { agregarReporte, traerReportes } from '../../api/apiReportes'
import { useState } from 'react'
import { NumericFormat } from 'react-number-format'

import Editar from '../../assetss/editar.svg'
import Descargar from '../../assetss/descargar.svg'

export const FormEditar = ({ setContentAlert, setReportes, reporte, modalReporte, pos }) => {
  const [fechaTrabajo, setFechaTrabajo] = useState(reporte.fecha_trabajo)
  const [idSisvadi, setIdSisvadi] = useState(reporte.id_sisvadi)
  const [nombreCentral, setNombreCentral] = useState(reporte.nombre_central)
  const [nombreEmpresa, setNombreEmpresa] = useState(reporte.nombre_empresa)
  const [total, setTotal] = useState(reporte.total)
  const [per90, setPer90] = useState(reporte.per_90)
  const [per10, setPer10] = useState(reporte.per_10)
  const [observaciones, setObservaciones] = useState(reporte.observaciones)

  const [btnEditar, setBtnEditar] = useState("Editar")
  const [disabled, setDisabled] = useState(true)

  const [puntajesValoresEditar, setPuntajesValoresEditar] = useState([
    {
      puntaje_L2001: reporte.PL_2001,
      valor_L2001: reporte.VL_2001,
      total: reporte.PL_2001 * reporte.VL_2001
    },
    {
      puntaje_N2003: reporte.PN_2003,
      valor_N2003: reporte.VN_2003,
      total: reporte.PN_2003 * reporte.VN_2003
    },
    {
      puntaje_LC2005: reporte.PLC_2005,
      valor_LC2005: reporte.VLC_2005,
      total: reporte.PLC_2005 * reporte.VLC_2005
    },
    {
      puntaje_LZ2006: reporte.PLZ_2006,
      valor_LZ2006: reporte.VLZ_2006,
      total: reporte.PLZ_2006 * reporte.VLZ_2006
    }
  ])

  console.log(puntajesValoresEditar)
  const changePuntajeValores = (e, pos) => {
    const newArr = [...puntajesValoresEditar]
    const objActual = Object.keys(newArr[pos])

    newArr[pos][e.target.name] = parseFloat(e.target.value)
    newArr[pos]['total'] = newArr[pos][objActual[0]] * newArr[pos][objActual[1]]
    const total = newArr.reduce((acum, b) => acum + b['total'], 0).toFixed(4)

    setTotal(total * 1.0)
    setPer90((total * 0.9).toFixed(4) * 1.0)
    setPer10((total * 0.1).toFixed(4) * 1.0)
    setPuntajesValoresEditar(newArr)
  }

  const editarReporte = async (e, pos) => {
    e.preventDefault()
    setBtnEditar("editando...")
    setDisabled(false)
    const data = new FormData(e.target)
    data.append('accion', 'editar_reporte')
    const response = await agregarReporte(data)
    if (response === 'Registrado correctamente') {
      let arr = await traerReportes()
      setBtnEditar("Editar")
      setDisabled(true)
      setTimeout(() => {
        modalReporte('.modalEditar', pos)
        setContentAlert("Editar")
        setTimeout(() => {
          setContentAlert(undefined)
        }, 4000);
        // alertMessage('Agregado correctamente!')
      }, 1000)
      setTimeout(async () => {
        setReportes(arr.data.filter(rep => rep.borrador_logico !== '1'))
      }, 1500)
    }
  }
  return (
    <div
      className='modal fade modalEditar'
      id=''
      tabIndex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <img src={Editar} alt='' />
            <h3 className='modal-title' id='exampleModalLabel'>
              Editar Reporte
            </h3>
          </div>
          <div className='modal-body'>
            <form
              onSubmit={e => editarReporte(e, pos)}
              className='editar_reporte'
              encType='multipart/form-data'
            >
              <div className='head-form'>
                <div className='input'>
                  <input
                    type='month'
                    name='fecha_trabajo'
                    id='fecha_trabajo'
                    onChange={e => setFechaTrabajo(e.target.value)}
                    value={fechaTrabajo}
                    placeholder='Mes/Año'
                    required
                  />
                </div>
                <div className='input'>
                  <input
                    type='number'
                    name='id_sisvadi'
                    id='id_sisvadi'
                    onChange={e => setIdSisvadi(e.target.value)}
                    value={idSisvadi}
                    placeholder='ID "SISVADI"'
                    required
                  />
                </div>
                <div className='input'>
                  <input
                    type='text'
                    name='nombre_central'
                    id='nombre_central'
                    onChange={e => setNombreCentral(e.target.value)}
                    value={nombreCentral}
                    placeholder='Central'
                    required
                  />
                </div>
                <div className='input'>
                  <input
                    type='text'
                    name='nombre_empresa'
                    id='nombre_empresa'
                    onChange={e => setNombreEmpresa(e.target.value)}
                    value={nombreEmpresa}
                    placeholder='Empresa'
                    required
                  />
                </div>
              </div>

              <div className='puntajes-valores'>
                <div className='puntaje-valor'>
                  <div className='input'>
                    <input
                      type='number'
                      name='puntaje_L2001'
                      className='puntaje puntaje_L2001'
                      onChange={e => changePuntajeValores(e, 0)}
                      value={puntajesValoresEditar[0].puntaje_L2001}
                      placeholder='L/2001'
                      step=".01"
                      id='puntaje_L2001'
                    />
                  </div>
                  <div className='input'>
                    <input
                      type='number'
                      name='valor_L2001'
                      className='valor valor_L2001'
                      onChange={e => changePuntajeValores(e, 0)}
                      value={puntajesValoresEditar[0].valor_L2001}
                      placeholder='$L/2001'
                      step=".01"
                      id='valor_L2001'
                    />
                  </div>
                </div>
                <div className='puntaje-valor'>
                  <div className='input'>
                    <input
                      type='number'
                      name='puntaje_N2003'
                      className='puntaje puntaje_N2003'
                      onChange={e => changePuntajeValores(e, 1)}
                      value={puntajesValoresEditar[1].puntaje_N2003}
                      placeholder='N/2003'
                      step=".01"
                      id='puntaje_N2003'
                    />
                  </div>
                  <div className='input'>
                    <input
                      type='number'
                      name='valor_N2003'
                      className='valor valor_N2003'
                      onChange={e => changePuntajeValores(e, 1)}
                      value={puntajesValoresEditar[1].valor_N2003}
                      placeholder='$N/2003'
                      step=".01"
                      id='valor_N2003'
                    />
                  </div>
                </div>
                <div className='puntaje-valor'>
                  <div className='input'>
                    <input
                      type='number'
                      name='puntaje_LC2005'
                      className='puntaje puntaje_LC2005'
                      onChange={e => changePuntajeValores(e, 2)}
                      value={puntajesValoresEditar[2].puntaje_LC2005}
                      placeholder='LC/2005'
                      step=".01"
                      id='puntaje_LC2005'
                    />
                  </div>
                  <div className='input'>
                    <input
                      type='number'
                      name='valor_LC2005'
                      className='valor valor_LC2005'
                      onChange={e => changePuntajeValores(e, 2)}
                      value={puntajesValoresEditar[2].valor_LC2005}
                      placeholder='$LC/2005'
                      step=".01"
                      id='valor_LC2005'
                    />
                  </div>
                </div>
                <div className='puntaje-valor'>
                  <div className='input'>
                    <input
                      type='number'
                      name='puntaje_LZ2006'
                      className='puntaje puntaje_LZ2006'
                      onChange={e => changePuntajeValores(e, 3)}
                      value={puntajesValoresEditar[3].puntaje_LZ2006}
                      placeholder='LZ/2006'
                      step=".01"
                      id='puntaje_LZ2006'
                    />
                  </div>
                  <div className='input'>
                    <input
                      type='number'
                      name='valor_LZ2006'
                      className='valor valor_LZ2006'
                      onChange={e => changePuntajeValores(e, 3)}
                      value={puntajesValoresEditar[3].valor_LZ2006}
                      placeholder='$LZ/2006'
                      step=".01"
                      id='valor_LZ2006'
                    />
                  </div>
                </div>
              </div>

              <div className='porcentajes-total'>
                <div className='input'>
                  <NumericFormat
                    thousandSeparator='.'
                    decimalSeparator=','
                    step='.00'
                    name='per_10'
                    className='per_10'
                    id='per_10'
                    value={per10 !== 0 ? per10 : ''}
                    placeholder='10%'
                    readOnly
                    required
                  />
                </div>
                <div className='input'>
                  <NumericFormat
                    thousandSeparator='.'
                    decimalSeparator=','
                    step='.00'
                    name='per_90'
                    className='per_90'
                    id='per_90'
                    value={per90 !== 0 ? per90 : ''}
                    placeholder='90%'
                    readOnly
                    required
                  />
                </div>
                <div className='input'>
                  <NumericFormat
                    value={total !== 0 ? total : ''}
                    thousandSeparator='.'
                    decimalSeparator=','
                    step='.00'
                    name='total'
                    className='total'
                    id='total'
                    placeholder='Total'
                    readOnly
                    required
                  />
                </div>
              </div>
              <div className='input'>
                <textarea
                  name='observaciones'
                  id='observaciones'
                  cols='30'
                  rows='3'
                  placeholder='Observaciones...'
                  onChange={e => setObservaciones(e.target.value)}
                  value={observaciones}
                >
                  {observaciones}
                </textarea>
              </div>
              <div className='subir-archivo'>
                <input
                  type='file'
                  name='descarga_materiales[]'
                  id='descarga_materiales'
                  multiple
                />
                {/* <button type='button'>
                  <img src={Descargar} alt='' /> Subir Archivo
                </button> */}
              </div>
              <div className='btn-actions'>
                <button
                  type='button'
                  className='btn-close btnFormAgregar'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => modalReporte('.modalEditar', pos)}
                >
                  Cancelar
                </button>
                {disabled && 
                <button type='submit'>
                  <img src={Editar} alt='' />
                  {btnEditar}
                </button>
                }
                {!disabled &&
                <button type='submit' disabled>
                  <img src={Editar} alt='' />
                  {btnEditar}
                </button>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
