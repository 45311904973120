import Descargar from '../assetss/descargar.svg'
import Restaurar from '../assetss/restaurar.png'
import Agregar from '../assetss/agregar.svg'
import Editar from '../assetss/editar.svg'
import Eliminar from '../assetss/papelera.svg'
import { saveAs } from 'file-saver'
//style
import '../styles/components/button.css'

const Button = ({ contentButton, buttonAction = null, firstParam = null, secondParam = null }) => {
  const possibleBtn = {
    Agregar: {
      class: 'btn-add',
      icon: Agregar
    },
    Eliminar: {
      class: 'btn-delete',
      icon: Eliminar
    },
    Editar: {
      class: 'btn-edit',
      icon: Editar
    },
    Restaurar: {
      class: 'btn-restore',
      icon: Restaurar
    },
    Descargar: {
      class: 'btn-download',
      icon: Descargar
    }
  }
  if (buttonAction != null) {
    if (firstParam == "") {
      return (
        <button
          disabled
          className={`btn ${possibleBtn[contentButton].class}`}
          onClick={() => buttonAction(firstParam, secondParam)}
        >
          <img
            src={possibleBtn[contentButton].icon}
            style={{ height: '20px' }}
            alt=''
          />
          {contentButton}
        </button>
      )
    } else {
      return (
        <button

          className={`btn ${possibleBtn[contentButton].class}`}
          onClick={() => buttonAction(firstParam, secondParam)}
        >
          <img
            src={possibleBtn[contentButton].icon}
            style={{ height: '20px' }}
            alt=''
          />
          {contentButton}
        </button>
      )
    }

  } else {
    return <></>
  }
}
export default Button
