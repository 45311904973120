import "../../styles/components/alert.css"
import Descargar from '../../assetss/descargar.svg'
import Restaurar from '../../assetss/reset.svg'
import Agregar from '../../assetss/agregar.svg'
import Editar from '../../assetss/editar.svg'
import Eliminar from '../../assetss/papelera.svg'

const Alert = ({ type, contentAlert }) => {
    const possiblesAlertsLogin = {
        "Coincide": {
            message: "Ingresaste correctamente",
            class: "alert-login-success",

        },
        "No coincide": {
            message: "Email o contraseña no coinciden",
            class: "alert-login-failed"
        }
    }
    const possibleAlert = {
        "Agregar": {
            message: "Reporte agregado",
            class: 'alert-add',
            icon: Agregar
        },
        "Eliminar": {
            message: "Reporte eliminado",
            class: 'alert-delete',
            icon: Eliminar
        },
        "Editar": {
            message: "Reporte editado",
            class: 'alert-edit',
            icon: Editar
        },
        "Restaurar": {
            message: "Reporte restaurado",
            class: 'alert-restore',
            icon: Restaurar
        },
        "Descargar": {
            message: "Archivo descargado",
            class: 'alert-download',
            icon: Descargar
        }
    }

    if (type == "Login") {
        return (
            <div className={`alert ${possiblesAlertsLogin[contentAlert].class}`}>
                <div className="border"></div>
                <div className="content">
                    <p>{possiblesAlertsLogin[contentAlert].message}</p>
                </div>
            </div>
        )
    } else {
        return (
            <div className={`alert ${possibleAlert[contentAlert].class}`}>
                <div className="border"></div>
                <div className="content">
                    <img src={possibleAlert[contentAlert].icon} alt="" />
                    <p>{possibleAlert[contentAlert].message}</p>
                </div>
            </div>
        )
    }

}

export default Alert;