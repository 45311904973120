import axios from 'axios'
const url = 'https://cotytel.com/api-php/'

export const traerReportes = async () => {
  let endpoint = url + 'reporte/index.php'
  return axios.get(endpoint)
}

export const agregarReporte = async data => {
  let endpoint = url + 'reporte/index.php'
  const response = await axios.post(endpoint, data, {
    headers: {
      Accept: 'application/json'
    }
  })
  return response.data
}
